export default function() {

    document.addEventListener('refreshIndexListeners', (event) => {
      // GEPAL-123: this listener was added for the sidepanel, which initially does not have any file uploads in the DOM.
      event.preventDefault();
      handleCustomFileUploaders();
    });

    handleCustomFileUploaders();

    function handleCustomFileUploaders() {
      /*
       * For now, we only support file uploaders without the 'multiple' attribute
       */
      const customFileUploaders = document.querySelectorAll('.js-custom-file-uploader');

      if (customFileUploaders.length) {
        for (let i = 0, len = customFileUploaders.length; i < len; i++) {
          const _self = customFileUploaders[i];
          const input = _self.querySelector('[type=file]');
          const label = _self.querySelector('.js-custom-file-uploader__label');

          label.dataset.originalValue = label.textContent;

          input.addEventListener('focus', function () {
            _self.classList.add('custom-file-uploader--focus');
          });

          input.addEventListener('blur', function () {
            _self.classList.remove('custom-file-uploader--focus');
          });

          input.addEventListener('change', function () {
            const fileNameParts = input.value.split('\\');
            if (input.value === '' ) {
              label.textContent = label.dataset.originalValue;
            } else {
              label.textContent = fileNameParts[fileNameParts.length - 1]; // check XSS
            }
          });
        }
      }
    }
}
