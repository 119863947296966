/**
 * Add global styling
 */
require('./css/global.scss');

/**
 * Add images to asset-building (and enable use in twig-templates)
 */
const imagesContext = require.context('./images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

/**
 * Add dropdowns
 */
import dropdown from './js/dropdown';
dropdown();

/**
 * Add tabs
 */
import tabs from './js/tabs';
tabs();

/**
 * Add toggles
 */
import toggles from './js/toggles';
toggles();

/**
 * Add customFileUploader
 */
import customFileUploader from './js/custom-file-uploader';
customFileUploader();

/**
 * Add taxon nav
 */
import taxonNav from './js/taxon-nav';
taxonNav();


/**
 * Add main-search nav
 */
import mainSearch from './js/main-search';
mainSearch();
