export default function () {

    const dropdowns = document.querySelectorAll('.dropdown');

    const body = document.querySelector('body');

    function removeAllDropdowns() {
        for (let i = 0, len = dropdowns.length; i < len; i++) {
            dropdowns[i].classList.remove('dropdown--open');
        }
    }

    function removeDropdownsOnBodyClick() {

        removeAllDropdowns();

        body.removeEventListener('click', removeDropdownsOnBodyClick);
    }

    for (let i = 0, len = dropdowns.length; i < len; i++) {

        const trigger = dropdowns[i].querySelector('.dropdown__trigger');

        if(trigger) {
            trigger.addEventListener('click', function (e) {

                e.stopPropagation();
                e.preventDefault();

                if(trigger.parentNode.classList.contains('dropdown--open')) {
                    removeAllDropdowns();
                    body.removeEventListener('click', removeDropdownsOnBodyClick);
                } else {
                    removeAllDropdowns();
                    body.addEventListener('click', removeDropdownsOnBodyClick);
                    trigger.parentNode.classList.add('dropdown--open');
                }

            });
        }
    }

}
