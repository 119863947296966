export default function () {

    /**
     * Toggle taxon-dropdown active state
     */
    const taxondropdowntrigger = document.querySelector('[data-taxon-dropdown-trigger]');
    const taxondropdown = document.querySelector('.taxon-dropdown');

    function toggleDropdown()
    {
        if (taxondropdown.classList.contains('taxon-dropdown--active') ) {
            taxondropdown.classList.remove('taxon-dropdown--active');
        } else {
            showDropdown();
        }
    }

    function hideDropdown(event)
    {
        if (!taxondropdown.contains(event.target)) {
            document.removeEventListener('click', hideDropdown);
            taxondropdown.classList.remove('taxon-dropdown--active');
        }
    }

    function showDropdown()
    {
        document.addEventListener('click', hideDropdown);
        taxondropdown.classList.add('taxon-dropdown--active');
    }

    taxondropdowntrigger.addEventListener('click', toggleDropdown, true);

    /**
     * Add event listener to taxon-triggers
     */
    const taxontriggers = document.querySelectorAll('[data-main-taxon-trigger]');
    for (let i = 0, len = taxontriggers.length; i < len; i++) {
        const taxon = taxontriggers[i];

        taxon.addEventListener('click', function (e) {
            e.preventDefault();
            activateTaxon(this.dataset.mainTaxonTrigger);
        });

        //make first taxon active
        if (i === 0 && window.matchMedia('(min-width: 720px)').matches) {
            activateTaxon(taxon.dataset.mainTaxonTrigger);
        }

    }

    function activateTaxon(id)
    {

        /**
         * Loop all subnavs, de-activate all, except the to-be-actived one
         */
        const subnavs = document.querySelectorAll('[data-main-taxon-subnav]');
        for (let i = 0, len = subnavs.length; i < len; i++) {
            const subnav = subnavs[i];
            if (subnav.dataset.mainTaxonSubnav === id) {
                subnav.classList.add('tax-nav__subs--active')
            } else {
                subnav.classList.remove('tax-nav__subs--active')
            }
        }

        /**
         * Loop all triggers, de-activate all, except the to-be-actived one
         */
        const taxontriggers = document.querySelectorAll('[data-main-taxon-trigger]');
        for (let i = 0, len = taxontriggers.length; i < len; i++) {
            const trigger = taxontriggers[i];

            if (trigger.dataset.mainTaxonTrigger === id) {
                trigger.classList.add('tax-nav__main-item--active');
                activateSubsContainer();
            } else {
                trigger.classList.remove('tax-nav__main-item--active');
            }
        }

    }

    /**
     * Activate subscontainer (for mobile devices)
     */
    function activateSubsContainer() {
      const taxNav = document.querySelector('.tax-nav');
      taxNav.classList.add('tax-nav--subs-visible');
    }

    /**
     * Deactivate subscontainer (for mobile devices)
     */
    function deActivateSubsContainer() {
      const taxNav = document.querySelector('.tax-nav');
      taxNav.classList.remove('tax-nav--subs-visible');
    }

    /**
     * Add event listener to btn to de-activate subs-container
     */
    const taxBackBtn = document.querySelector('.js-tax-back');
    taxBackBtn.addEventListener('click', function (e) {
      e.preventDefault();
      deActivateSubsContainer();
    });

}
