export default function () {

    /**
     * When an element with [data-toggles]-attribute is clicked,
     * the visibility of the corresponding element will be toggled by a class-name.
     *
     * @type {NodeListOf<Element>}
     */

    const toggles = document.querySelectorAll('[data-toggles]');

    for (let i = 0, len = toggles.length; i < len; i++) {

        const toggle = toggles[i];

        toggle.addEventListener('click', function () {
            toggleElement(toggle.getAttribute('data-toggles'));
        });

    }

    function toggleElement(id) {

        const el = document.querySelector('#' + id);
        el.classList.toggle('js-hidden');

    }

}
