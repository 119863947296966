export default function () {

    const tabSets = document.querySelectorAll('.tabs');

    for (let i = 0, len = tabSets.length; i < len; i++) {

        const tabSet = tabSets[i];

        const triggers = tabSet.querySelectorAll('.tabs__trigger');

        for (let tabNo = 0, len = triggers.length; tabNo < len; tabNo++) {

            const trigger = triggers[tabNo];

            trigger.addEventListener('click', function (e) {
                e.preventDefault();
                activateTab(tabSet, tabNo)
            });

        }

        /* set first tab active */
        activateTab(tabSet, 0);

    }

    function activateTab(tabSet, tabNo) {

        /* inactivate currently active tabs */
        const activeTrigger = tabSet.querySelector('.tabs__trigger--active');
        const activeSheet = tabSet.querySelector('.tabs__sheet--active');

        if(activeTrigger) {
            activeTrigger.classList.remove('tabs__trigger--active');
        }

        if(activeSheet) {
            activeSheet.classList.remove('tabs__sheet--active');
        }

        /* activate tabs */
        tabSet.querySelector('.tabs__trigger:nth-child(' + (tabNo + 1) + ')').classList.add('tabs__trigger--active');
        tabSet.querySelector('.tabs__sheet:nth-child(' + (tabNo + 1) + ')').classList.add('tabs__sheet--active');

    }

}
