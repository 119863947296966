export default function () {

    /**
     * Open search as seperate box on mobile
     */
    const mainSearch = document.querySelector('.js-main-search');
    const toggle = document.querySelector('.js-main-search-toggle');

    toggle.addEventListener('click', function (e) {
        mainSearch.classList.toggle('main-search__form--open');
        toggle.classList.toggle('main-search__button--toggle-close');

        if (mainSearch.classList.contains('main-search__form--open')) {
            document.querySelector('.main-search__input').focus();
        }
    });

}
